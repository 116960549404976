$(document).ready(function(){


    var filesList = {};

    var maxSize = 8388608; //10mo

    var sizeIsValid = function() {
        return maxSize >= totalSize();
    };

    //https://gist.github.com/lanqy/5193417
    var bytesToSize = function(bytes) {
        var sizes = ['Octets', 'KO', 'MO', 'GO', 'TO'];
        if (0 === bytes){
            return 'n/a';
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        if (0 === i) {
            return bytes + ' ' + sizes[i];
        }
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    };

    var totalSize = function(key) {

        var size = 0;

        if(filesList[key].length){
            for(var i in filesList[key]) {
                size += filesList[key][i].size;
            }
        }
        return size;
    };

    var drawFileList = function(input) {
        var parent = input.parents('.gfield')
        var container = parent.find('.files-list');

        container.empty();

        var key = input.attr('name');


        parent.find('.w-files__selected').text(filesList[key].length ? 'Votre fichier' : 'Aucun fichier séléctionné');


        for (var i = 0; i < filesList[key].length; i++) {
            var file = filesList[key][i];
            container.append('<div class="file-item file"><i data-index="'+i+'" class="file-remover icon icon-delete file__delete"></i> <span class="name file__name">' + file.name + '</span> <span class="size file__size">(' + bytesToSize(file.size) + ')</span></div>');
        }
    };



    var manageFileField = function(){

        $('body').on('change', 'input[type="file"]', function(){
            var key = $(this).attr('name');

            filesList[key] = [];

            for (var i = 0; i < $(this)[0].files.length; i++) {
                var file = $(this)[0].files[i];
                //add to list
                filesList[key].push(file);

                drawFileList($(this));
            }
        });


        $(document).on('click', '.file-remover', function(){

            var input = $(this).parents('.gfield').find('input[type="file"]');

            var name = input.attr('name');

            filesList[name].splice($(this).data('index'), 1);
            drawFileList(input);
        });

        $('.ginput_container_fileupload').wrapInner( "<div class='w-input'></div>");

        $('.ginput_container_fileupload').parents('.gfield').append('<div class="w-files"><div class="w-files__selected">Aucun fichier sélectionné</div><div class="files-list w-files__content"></div></div>')

    };


    //manageFileField();


    $(document).bind('gform_confirmation_loaded', function(){
        $('.form-rgpd').hide();

        $('.form-confirmation-hide').hide();
        $('.form-confirmation-show').show();
    });


    jQuery(document).on('gform_page_loaded', function(event, form_id, current_page){


        if(parseInt(current_page, 10) > 1) {
            $('.form-next-hide').hide();
        }
        else{
            $('.form-next-hide').show();
        }

        if((6 == form_id && 3 === parseInt(current_page, 10) ))
        {
            manageFileField();
        }

    });

    if($('#gform_fields_1').length || $('.upload').length){
        manageFileField();
    }



});

